<template>
  <div>
    <b-card title="基本信息">
      <b-row>
        <b-col cols="6">
          <b-form-group label="产品名称" label-for="basicInput">
            <b-form-input id="basicInput" v-model="deviceInfoVo.productName" disabled />
          </b-form-group>
          <b-form-group label="设备ID" label-for="basicInput">
            <b-form-input id="basicInput" v-model="deviceInfoVo.deviceId" disabled />
          </b-form-group>
          <!--          <b-form-group
                        label="（家庭）群组"
                        label-for="basicInput"
                    >
                      <b-form-input
                          id="basicInput" v-model="row.tenantName"
                      />
                    </b-form-group>-->
        </b-col>
        <b-col cols="6">
          <b-form-group label="设备状态" label-for="basicInput">
            <b-form-input id="basicInput" :value="statusFormat(deviceInfoVo.deviceStatus)" disabled />
          </b-form-group>
          <!--          <b-form-group
                        label="（家庭）群组"
                        label-for="basicInput"
                    >
                      <b-form-input
                          id="basicInput" v-model="row.tenantName"
                      />
                    </b-form-group>-->
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <!--          <b-form-group
              label="首次入网"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput" v-model="row.activeTime"
            />
          </b-form-group>-->
          <b-form-group label="标签" label-for="basicInput">
            <b-form-input id="basicInput" v-model="getTagInfo" disabled />
          </b-form-group>
          <b-form-group label="分组" label-for="basicInput">
            <b-form-input id="basicInput" v-model="deviceInfoVo.pathStr" disabled />
          </b-form-group>
          <!--          <b-form-group
                        label="房间名"
                        label-for="basicInput"
                    >
                      <b-form-input
                          id="basicInput" v-model="row.deviceStatus"
                      />
                    </b-form-group>-->
        </b-col>
      </b-row>
    </b-card>
    <b-card title="设备参数">
      <!--      <b-row>
        <b-col cols="6">
          <b-button style="margin-left: 90%" variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="changeDdetailInfo">
            保存
          </b-button>
        </b-col>
      </b-row>-->
      <b-row>
        <!--          <template v-for="(item,key,i) in detailInfoOb">
            <b-form-group
                :label="key"
                label-for="basicInput"
            >
              <b-form-input
                  :id="key" :value="item" :ref="key"
              />

                <v-select
                    :id="key"
                    :value="value"
                    :label="lable"
                    :options="onoffOptions"
                    class="w-100"
                />
            </b-form-group>
          </template>-->

        <template
          v-if="deviceInfoVo.productType === productOptions.FALL_CHECK_PRODUCT.value || deviceInfoVo.productType === productOptions.FALL_4G_CHECK_PRODUCT.value">
          <b-col cols="6">
            <b-form-group label="悬挂方式" label-for="basicInput">
              <v-select id="way" v-model="detailInfoOb.way" :reduce="val => val.value" :options="fallWayOptions"
                class="w-100 remove-clear" />
            </b-form-group>

            <b-form-group label="蜂鸣器" label-for="basicInput">
              <v-select id="buzzer" v-model="detailInfoOb.buzzer" :reduce="val => val.value" :options="onOffOptions"
                class="w-100 remove-clear" />
            </b-form-group>

            <b-form-group label="跌倒警报间隔时间" label-for="basicInput">
              <!--<b-form-input type="number" ref="fallTmpTime" id="fallTmpTime" v-model="detailInfoOb.fallTmpTime"  @change="timeInputChange('fallTmpTime')" />-->
              <v-select id="fallTmpTime" v-model="detailInfoOb.fallTmpTime" :reduce="val => val.value"
                :options="fallTmpTimeOptions" class="w-100 remove-clear" />
            </b-form-group>

            <b-form-group label="多人监测跌倒警报" label-for="basicInput">
              <v-select id="manyPeopleWarning" v-model="detailInfoOb.manyPeopleWarning" :reduce="val => val.value"
                :options="onOffOptions" class="w-100 remove-clear" />
            </b-form-group>

            <b-form-group label="长时间无人" label-for="basicInput">
              <v-select id="noticeNobody" v-model="detailInfoOb.noticeNobody" :reduce="val => val.value"
                :options="onOffOptions" class="w-100 remove-clear" />
            </b-form-group>

<!--            <b-form-group label="长时间滞留" label-for="basicInput">
              <v-select id="fallFreeze" v-model="detailInfoOb.fallFreeze" :reduce="val => val.value"
                :options="onOffOptions" class="w-100 remove-clear" />
            </b-form-group>-->

            <b-form-group label="离线通知" label-for="basicInput">
              <v-select id="noticeDeviceOffline" v-model="detailInfoOb.noticeDeviceOffline" :reduce="val => val.value"
                :options="onOffOptions" class="w-100 remove-clear" />
            </b-form-group>

          </b-col>
          <b-col cols="6">

            <b-form-group label="高度" label-for="basicInput">
              <b-form-input type="number" ref="height" id="height" v-model="detailInfoOb.height" />
            </b-form-group>

            <b-form-group label="蜂鸣时长" label-for="basicInput">
              <v-select id="buzzerTime" v-model="detailInfoOb.buzzerTime" :reduce="val => val.value"
                :options="buzzerTimeOptions" class="w-100 remove-clear" />
            </b-form-group>


            <b-form-group label="跌倒起身恢复" label-for="basicInput">
              <v-select id="fallResumeCloseWarning" v-model="detailInfoOb.fallResumeCloseWarning"
                :reduce="val => val.value" :options="onOffOptions" class="w-100 remove-clear" />
            </b-form-group>


            <b-form-group label="指示灯" label-for="basicInput">
              <v-select id="light" v-model="detailInfoOb.light" :reduce="val => val.value" :options="onOffOptions"
                class="w-100 remove-clear" />
            </b-form-group>

            <b-form-group label="长时间无人间隔时间" label-for="basicInput">
              <b-form-input type="number" ref="nobodyTmpTime" id="nobodyTmpTime"
                :value="tmpTimeFormat(detailInfoOb.nobodyTmpTime)" @change="timeInputChange('nobodyTmpTime')" />
            </b-form-group>

<!--            <b-form-group label="长时间滞留间隔时间" label-for="basicInput">
              <b-form-input type="number" ref="fallFreezeTime" id="fallFreezeTime"
                            :value="tmpTimeFormat(detailInfoOb.fallFreezeTime)" @change="timeInputChange('fallFreezeTime')" />
            </b-form-group>

            <b-form-group label="离线通知次数" label-for="basicInput">
              <v-select id="noticeDeviceOfflineCount" v-model="detailInfoOb.noticeDeviceOfflineCount" :reduce="val => val.value"
                        :options="noticeOfflineCountOptions" class="w-100 remove-clear" />
            </b-form-group>-->
          </b-col>
        </template>

        <template
          v-if="deviceInfoVo.productType === productOptions.SLEEP_WIFI_PRODUCT.value || deviceInfoVo.productType === productOptions.SLEEP_4G_PRODUCT.value">
          <b-col cols="6">
            <b-form-group label="心率异常" label-for="basicInput">
              <v-select id="heartSwitch" v-model="detailInfoOb.heartSwitch" :reduce="val => val.value"
                :options="onOffOptions" class="w-100 remove-clear" />
            </b-form-group>

            <b-form-group label="心率最小值" label-for="basicInput">
              <b-form-input type="number" ref="heartMin" id="heartMin" v-model="detailInfoOb.heartMin" />
            </b-form-group>

            <b-form-group label="心率最大值" label-for="basicInput">
              <b-form-input type="number" ref="heartMax" id="heartMax" v-model="detailInfoOb.heartMax" />
            </b-form-group>

            <b-form-group label="呼吸异常" label-for="basicInput">
              <v-select id="breathSwitch" v-model="detailInfoOb.breathSwitch" :reduce="val => val.value"
                :options="onOffOptions" class="w-100 remove-clear" />
            </b-form-group>

            <b-form-group label="呼吸最小值" label-for="basicInput">
              <b-form-input type="number" ref="breathMin" id="breathMin" v-model="detailInfoOb.breathMin" />
            </b-form-group>

            <b-form-group label="呼吸最大值" label-for="basicInput">
              <b-form-input type="number" ref="breathMax" id="breathMax" v-model="detailInfoOb.breathMax" />
            </b-form-group>

            <b-form-group label="指示灯" label-for="basicInput">
              <v-select id="light" v-model="detailInfoOb.light" :reduce="val => val.value" :options="onOffOptions"
                class="w-100 remove-clear" />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="呼吸暂停警报" label-for="basicInput">
              <v-select id="breathStop" v-model="detailInfoOb.breathStop" :reduce="val => val.value"
                :options="onOffOptions" class="w-100 remove-clear" />
            </b-form-group>
            <b-form-group label="离床超时" label-for="basicInput">
              <v-select id="outBedSwitch" v-model="detailInfoOb.outBedSwitch" :reduce="val => val.value"
                :options="onOffOptions" class="w-100 remove-clear" />
            </b-form-group>

            <b-form-group label="离床超时时间" label-for="basicInput">
              <b-form-input type="number" ref="outBedTmpTime" id="outBedTmpTime"
                :value="tmpTimeFormat(detailInfoOb.outBedTmpTime)" @change="timeInputChange('outBedTmpTime')" />
            </b-form-group>

            <b-form-group label="长时间未体动" label-for="basicInput">
              <v-select id="notMoveSwitch" v-model="detailInfoOb.notMoveSwitch" :reduce="val => val.value"
                :options="onOffOptions" class="w-100 remove-clear" />
            </b-form-group>

            <b-form-group label="未体动时间" label-for="basicInput">
              <b-form-input type="number" ref="notMoveTmpTime" id="notMoveTmpTime"
                :value="tmpTimeFormat(detailInfoOb.notMoveTmpTime)" @change="timeInputChange('notMoveTmpTime')" />
            </b-form-group>

            <b-form-group label="设备离线通知" label-for="basicInput">
              <v-select id="noticeDeviceOffline" v-model="detailInfoOb.noticeDeviceOffline" :reduce="val => val.value"
                :options="onOffOptions" class="w-100 remove-clear" />
            </b-form-group>

<!--            <b-form-group label="离线通知次数" label-for="basicInput">
              <v-select id="noticeDeviceOfflineCount" v-model="detailInfoOb.noticeDeviceOfflineCount" :reduce="val => val.value"
                        :options="noticeOfflineCountOptions" class="w-100 remove-clear" />
            </b-form-group>-->

          </b-col>
        </template>
      </b-row>
      <b-row>
        <b-button style="margin-left: 1rem;" variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="changeDdetailInfo">
          保存
        </b-button>
      </b-row>
    </b-card>
    <b-card title="物联网卡信息" v-if="deviceInfoVo.productType === productOptions.FALL_4G_CHECK_PRODUCT.value || deviceInfoVo.productType === productOptions.SLEEP_4G_PRODUCT.value">
      <b-row >
        <b-col cols="6">
          <b-form-group label="ICCID">
            <b-form-input type="text" :disabled="true" v-model="deviceInfoVo.iccid" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <div>
      <b-button class="w-15" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" to="/device/deviceList">
        返回
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BCard, BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import request from '@/api/request'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea, vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      deviceConfigId: this.$route.query.deviceConfigId,
      deviceInfoVo: {},
      detailInfoOb: {},
      deviceStatusOptions: [
        {
          value: 0,
          label: '未激活'
        }, {
          value: 1,
          label: '离线'
        }, {
          value: 2,
          label: '在线'
        }, {
          value: 3,
          label: '激活'
        }
      ],

      buzzerTimeOptions: [
        {
          value: 0,
          label: '持续'
        }, {
          value: 1 * 60 * 1000,
          label: '1分钟'
        }, {
          value: 5 * 60 * 1000,
          label: '5分钟'
        }, {
          value: 10 * 60 * 1000,
          label: '10分钟'
        }
      ],

      fallTmpTimeOptions: [
        {
          value: 0,
          label: '立即'
        }, {
          value: 1 * 60 * 1000,
          label: '1分钟'
        }, {
          value: 3 * 60 * 1000,
          label: '3分钟'
        }, {
          value: 10 * 60 * 1000,
          label: '10分钟'
        }
      ],

      onOffOptions: [
        {
          value: true,
          label: '打开'
        }, {
          value: false,
          label: '关闭'
        }
      ],
      fallWayOptions: [
        {
          value: 0,
          label: '顶部平挂',
        }
      ],

      productOptions: {
        FALL_CHECK_PRODUCT: { value: 1 },
        SLEEP_WIFI_PRODUCT: { value: 2 },
        FALL_4G_CHECK_PRODUCT: { value: 3 },
        SLEEP_4G_PRODUCT: { value: 4 },
      },

      noticeOfflineCountOptions: [
        {
          value: -1,
          label: '持续'
        },{
          value: 1,
          label: '1次'
        }
      ],

    }
  },

  created() {
    this.getDeviceInfoVo();
    this.otherDataInit();
  },

  computed: {
    /**
     * 计算属性
     */
    getTagInfo() {
      var tagStr = "";
      let tags = this.deviceInfoVo.tagInfoVos
      for (let i in tags) {
        if (i > 0) {
          tagStr = tagStr + "，"
        }
        tagStr = tagStr + tags[i].tagName;
      }
      return tagStr;
    },
  },

  methods: {
    getDeviceInfoVo() {
      request.get('tob/bDevice/getDeviceInfoVo', {
        deviceConfigId: this.deviceConfigId,
      })
        .then(res => {
          if (res.data.success) {
            this.deviceInfoVo = res.data.data
            this.setDetailInfoOb();
          } else {
            that.$refs.loginForm.setErrors(res.data.msg)
          }
        })
    },

    setDetailInfoOb() {
      this.detailInfoOb = JSON.parse(this.deviceInfoVo.detailInfo);
    },

    tmpTimeFormat(tmpTime) {
      return tmpTime / 1000 / 60;
    },

    timeInputChange(keyStr) {
      console.log("keyStr", keyStr)
      var inputV = Number(this.$refs[keyStr].localValue);
      if (inputV == undefined | isNaN(inputV)) {
        inputV = this.detailInfoOb[keyStr];
      } else {
        inputV = inputV * 1000 * 60;
      }
      //this.$refs[keyStr].value=inputV;
      this.detailInfoOb[keyStr] = inputV;
    },

    statusFormat(value) {
      for (let item of this.deviceStatusOptions) {
        if (item.value === value) {
          return item.label;
        }
      }
      return value;
    },

    changeDdetailInfo() {
      request.post('tob/bDevice/updateDeviceConfig', {
        configId: this.deviceConfigId,
        name: this.deviceInfoVo.name,
        detailInfo: JSON.stringify(this.detailInfoOb),
      })
        .then(res => {
          if (res.data.success) {
            this.getDeviceInfoVo();
            this.$bvToast.toast("保存成功", {
              title: `提示`,
              variant: 'success',
              solid: true
            })
          } else {
            this.getDeviceInfoVo();
            that.$refs.loginForm.setErrors(res.data.msg);
          }
        })
    },

    otherDataInit() {
      this.fallWayOptionsFormat();
    },

    //格式化后端返回的悬挂方式枚举
    fallWayOptionsFormat() {
      request.get('tob/bDevice/getFallWayEnum', {
      }).then(res => {
        if (res.data.success) {
          var enums = res.data.data;
          this.fallWayOptions = [];
          for (const key in enums) {
            var value = enums[key];
            var enumObject = {
              value: key - 0,
              label: value,
            }

            this.fallWayOptions.push(enumObject);
          }
          console.log("fallWayOptions", this.fallWayOptions)
        }
      })
    },

  }
}
</script>
<style lang="scss">
.remove-clear {
  .vs__clear {
    display: none;
  }
}
</style>
